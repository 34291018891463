.hero-section {
  /* background-color: #291181; */
  background: url("../../assets/image/background.png");
  background-size: cover;
  margin: 3.5rem;
  height: 85vh;
  display: flex;
}
.hero-section .hero-description {
  background-color: rgba(0, 0, 0, 0.55);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hero-inner {
  margin: 0 0 0 2.5rem;
}
.hero-description .text {
  position: relative;
  margin-left: 8rem;
  font-size: 1.5rem;
  padding-left: 1rem;
  letter-spacing: 0.05rem;
  font-weight: 900;
  transition: all 300ms ease;
  /* background-color: aquamarine; */
}
.hero-description .text::after {
  content: "";
  height: 0.25rem;
  width: 7rem;
  display: block;
  background-color: #eb83f8;
  position: absolute;
  /* top: 0; */
  left: -108px;
  top: 0.9rem;
  color: rgb(200, 200, 200);
}
.hero-description .name {
  font-size: 4.5rem;
  text-transform: capitalize;
  font-weight: 900;
  /* background-color: aquamarine; */
  line-height: 6rem;
  transition: all 300ms ease;
}
.hero-description .tagline {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 900;
  text-transform: capitalize;
  color: rgb(200, 200, 200);
  transition: all 300ms ease;
}
.hero-section .hero-image {
  /* background-color: brown; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero-section .hero-image img {
  background-size: cover;
  min-height: 100%;
  width: 100%;
  /* overflow: hidden; */
}
.hero-button {
  outline: none;
  position: relative;
  display: inline-block;
  width: 13rem;
  height: 3rem;
  background: #000;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  font-family: verdana;
  letter-spacing: 2px;
  transition: all 300ms ease;
  cursor: pointer;
  border: none;
  line-height: 3rem;
}
.hero-button span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.hero-button:before,
.hero-button:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: rgb(255, 255, 255);
}
.hero-button:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.hero-button:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.hero-button:hover {
  /* color: #000; */
  background: transparent;
}
.hero-button:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.hero-button:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.hero-button span:before,
.hero-button span:after {
  position: absolute;
  content: "";
  background: rgb(255, 255, 255);
}
.hero-button span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.hero-button span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.hero-button span:hover:before {
  width: 100%;
}
.hero-button span:hover:after {
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .hero-description .name {
    font-size: 5rem;
  }
  .hero-description .tagline {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 950px) {
  .hero-section {
    flex-direction: column;
  }
  .hero-description .name {
    font-size: 4rem;
  }
  .hero-description .tagline {
    font-size: 2rem;
  }
}
@media screen and (max-width: 750px) {
  .hero-section {
    flex-direction: column;
  }
  .hero-section .hero-description {
    padding: 3rem 0;
    width: 100%;
    /* background-color: khaki; */
    height: 50%;
    align-items: flex-start;
    padding-left: 1rem;
    /* background-color: aquamarine; */
  }
  .hero-description .text {
    font-size: 1.5rem;
  }
  .hero-description .name {
    font-size: 3.5rem;
  }
  .hero-description .tagline {
    font-size: 1.5rem;
  }
  .hero-section .hero-image {
    height: 100%;
  }
}
@media screen and (max-width: 600px) {
  .hero-section {
    margin: 0;
    height: 95vh;
  }
  .hero-description .name {
    padding: 0.5rem 0 0 0;
    font-size: 3.5rem;
  }
  .hero-description .text::after {
    height: 0.2rem;
    width: 10rem;
    top: 2rem;
    left: 0;
  }
  .hero-description .text {
    position: relative;
    margin-left: 0;
  }
  .hero-description .name {
    text-transform: capitalize;
    font-weight: 900;
    /* background-color: aquamarine; */
    line-height: 4rem;
  }


}
