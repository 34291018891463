*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  background-color: #000;
  position: relative;
  /* font-family: 'Courier New', Courier, monospace; */
  font-family: sans-serif;
  color: #fff;
  scroll-behavior: auto;


}
a{
  text-decoration: unset;
  color: unset;
}